/* eslint-disable @typescript-eslint/no-extra-parens */
import {
    Box,
    Collapse,
    List,
    ListItemButton,
    ListItemIcon,
    Typography,
    alpha,
} from '@deltasierra/react/components/core';
import { ArrowDropDownIcon, Folder } from '@deltasierra/react/icons';
import { truncate } from '@deltasierra/utilities/string';
import { Translations } from '@deltasierra/translations/react';
import { SimpleSkeletonList } from '@deltasierra/react/components/skeleton';
import { useAssetLibraryBrowse } from './hooks';

export type AssetLibraryNavigatorProps = Pick<
    ReturnType<typeof useAssetLibraryBrowse>,
    | 'collections'
    | 'currentCollection'
    | 'isLoadingCollections'
    | 'isOpenCollection'
    | 'onClickHeader'
    | 'onClickSelectCollection'
> & {
    onClickHeader: (T: string) => void;
    t: Translations<'AssetLibrary'>;
};

export const AssetLibraryNavigator = ({
    collections,
    currentCollection,
    isLoadingCollections,
    isOpenCollection,
    onClickHeader,
    onClickSelectCollection,
    t,
}: AssetLibraryNavigatorProps): JSX.Element => (
    <Box
        sx={theme => ({
            color: alpha(theme.palette.common.black, 0.6),
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '75vh',
            position: 'relative',
            width: '15rem',
        })}
    >
        <List
            component="nav"
            sx={{
                backgroundColor: 'common.white',
                borderRadius: '8px',
                height: '100%',
                overflowY: 'auto',
                paddingTop: 0,
                position: 'absolute',
                width: '15rem',
            }}
        >
            {isLoadingCollections ? (
                <Box sx={{ paddingTop: '1rem' }}>
                    <SimpleSkeletonList skeleton={[2, 3]} />
                </Box>
            ) : (
                collections.map(collectionInstance => (
                    <Box key={collectionInstance.id}>
                        <ListItemButton
                            sx={[
                                !isOpenCollection.has(collectionInstance.id) &&
                                    collectionInstance.data.some(({ id }) => id === currentCollection?.id) && {
                                        backgroundColor: 'primary.light',
                                        transition: 'ease',
                                    },
                                {
                                    alignItems: 'center',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                },
                            ]}
                            onClick={() => onClickHeader(collectionInstance.id)}
                        >
                            <Typography variant="h4">{truncate(collectionInstance.title, 20)}</Typography>
                            {isOpenCollection.has(collectionInstance.id) ? (
                                <ArrowDropDownIcon />
                            ) : (
                                <ArrowDropDownIcon style={{ rotate: '180deg' }} />
                            )}
                        </ListItemButton>
                        <Collapse in={isOpenCollection.has(collectionInstance.id)} timeout="auto" unmountOnExit>
                            {collectionInstance.data.map(collection => (
                                <List component="div" disablePadding key={collection.id}>
                                    <ListItemButton
                                        key={collection.id}
                                        sx={{
                                            backgroundColor:
                                                currentCollection?.id === collection.id
                                                    ? 'primary.light'
                                                    : 'transparent',
                                            cursor: 'pointer',
                                            width: '100%',
                                        }}
                                        onClick={() => onClickSelectCollection(collection)}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                marginRight: '-1rem',
                                                path: { strokeWidth: 2 },
                                                transform: 'scale(0.85)',
                                            }}
                                        >
                                            <Folder aria-label={t('Folder')} />
                                        </ListItemIcon>
                                        <Typography component="h5" variant="h6">
                                            {truncate(collection.title, 20)}
                                        </Typography>
                                    </ListItemButton>
                                </List>
                            ))}
                        </Collapse>
                    </Box>
                ))
            )}
        </List>
    </Box>
);

AssetLibraryNavigator.displayName = 'AssetLibraryNavigator';
