import { useTranslations } from '@deltasierra/translations/react';
import { AssetEdit, AssetEditProps } from './AssetEdit';
import { useAssetEdit, useDragAsset } from './hooks';

// TODO: These should be props that are passed in so we know where the crops and the like are
const containerWidth = 1500;
const containerHeight = 700;

export type AssetEditContainerProps = Pick<AssetEditProps, 'asset'>;

export const AssetEditContainer = ({ asset }: AssetEditContainerProps): JSX.Element => {
    const {
        cropperPosition,
        cropRatio,
        draggableAssetRef,
        handleCropRatio,
        handleFlipRotation,
        handleImageRotation,
        handleImageScale,
        handleResetImageRotation,
        handleResetImageScale,
        imageRotation,
        imageScale,
    } = useAssetEdit({ containerHeight, containerWidth });

    const { handleMouseDown, handleMouseMove, handleMouseUp, imageOffset, isDraggingAsset } = useDragAsset({
        draggableAssetRef,
    });
    const t = useTranslations('AssetEdit');

    return (
        <AssetEdit
            asset={asset}
            cropRatio={cropRatio}
            cropperPosition={cropperPosition}
            draggableAssetRef={draggableAssetRef}
            handleCropRatio={handleCropRatio}
            handleFlipRotation={handleFlipRotation}
            handleImageRotation={handleImageRotation}
            handleImageScale={handleImageScale}
            handleMouseDown={handleMouseDown}
            handleMouseMove={handleMouseMove}
            handleMouseUp={handleMouseUp}
            handleResetImageRotation={handleResetImageRotation}
            handleResetImageScale={handleResetImageScale}
            imageOffset={imageOffset}
            imageRotation={imageRotation}
            imageScale={imageScale}
            isDraggingAsset={isDraggingAsset}
            t={t}
        />
    );
};

AssetEditContainer.displayName = 'AssetEditContainer';
