import { useMemo, useRef, useState } from 'react';
import { ASPECT_RATIOS } from '../constants';
import { AssetEditState, Dimensions, RatioName } from '../types';

type UseAssetEditProps = {
    containerWidth: number;
    containerHeight: number;
};

export function useAssetEdit({ containerHeight, containerWidth }: UseAssetEditProps): AssetEditState {
    const [imageRotation, setImageRotation] = useState(0);
    const [imageScale, setImageScale] = useState(1);
    const [cropRatio, setCropRatio] = useState<RatioName>('Landscape (3:2)');

    const draggableAssetRef = useRef<HTMLImageElement>(null);

    // Slider will return a number or an array of numbers, in both of our cases we only want numbers
    // This accepts a setter, and will set it only if the value which is checked first is a number
    const handleSliderChange = (setter: (value: number) => void) => (_event: Event, value: number[] | number) => {
        if (typeof value === 'number') {
            setter(value);
        }
    };

    const handleFlipRotation = ({ max, min, value }: { value: number; min: number; max: number }) => {
        const newRotation = imageRotation + value;
        if (newRotation >= max) {
            setImageRotation(max);
        } else if (newRotation <= min) {
            setImageRotation(min);
        } else {
            setImageRotation(newRotation);
        }
    };

    const handleCropRatio = (ratio: RatioName) => setCropRatio(ratio);
    const handleImageRotation = handleSliderChange(setImageRotation);
    const handleImageScale = handleSliderChange(setImageScale);
    const handleResetImageRotation = (value: number) => setImageRotation(value);
    const handleResetImageScale = (value: number) => setImageScale(value);

    // TODO: Create Calculate Box size to calculate where the cropped box is meant to go
    const calculateBoxSize = () => {
        const containerAspectRatio = containerWidth / containerHeight;

        let boxHeight;
        let boxWidth;

        if (ASPECT_RATIOS[cropRatio] > containerAspectRatio) {
            // Match width, adjust height based on aspect ratio
            boxWidth = containerWidth * 0.5;
            boxHeight = boxWidth / ASPECT_RATIOS[cropRatio];
        } else {
            // Match height, adjust width based on aspect ratio
            boxHeight = containerHeight * 0.5;
            boxWidth = boxHeight * ASPECT_RATIOS[cropRatio];
        }

        return {
            boxHeight,
            boxWidth,
        };
    };

    const { boxHeight, boxWidth } = calculateBoxSize();

    // This sets the Cropper Position to be in the center of the Canvas
    const cropperPosition = useMemo<Dimensions>(
        () => ({
            bottom: (containerHeight + boxHeight) / 2,
            left: (containerWidth - boxWidth) / 2,
            right: (containerWidth + boxWidth) / 2,
            top: (containerHeight - boxHeight) / 2,
        }),
        [boxHeight, boxWidth, containerHeight, containerWidth],
    );

    return {
        cropRatio,
        cropperPosition,
        draggableAssetRef,
        handleCropRatio,
        handleFlipRotation,
        handleImageRotation,
        handleImageScale,
        handleResetImageRotation,
        handleResetImageScale,
        imageRotation,
        imageScale,
    };
}
