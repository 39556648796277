import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import Image from 'next/image';
import { AssetEditState, DraggableAssetProps } from './types';

export type AssetEditCanvasProps = DraggableAssetProps &
    Omit<AssetEditState, 'handleFlipRotation' | 'handleResetImageRotation' | 'handleResetImageScale'> & {
        asset: AssetFileFragmentFragment;
        t: Translations<'AssetEdit'>;
    };

// TODO: Set ratios for the canvas size: DSL-5024
export const AssetEditCanvas = ({
    asset,
    draggableAssetRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    imageOffset,
    imageRotation,
    imageScale,
    isDraggingAsset,
}: AssetEditCanvasProps): JSX.Element => (
    <Box
        sx={{
            border: 'solid',
            cursor: isDraggingAsset ? 'grabbing' : 'grab',
            height: '100%',
            maxHeight: '800px',
            maxWidth: '1500px',
            minHeight: '500px',
            overflow: 'hidden',
            position: 'relative',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
    >
        {/* TODO: Visual display of cropper tool overlay DSL-5045 */}
        {/* Shadow - Shows the shadow over the cropped out area */}
        {/* The selected bit is clipped out with clipPath */}
        {/* <Box
            sx={{
                '&::before': {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    content: '""',
                    height: '100%',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                },
                clipPath: `polygon(
                        0% 0%, 100% 0%, 100% 100%, 0% 100%,
                        0% ${cropperPosition.top}px,
                        ${cropperPosition.left}px ${cropperPosition.top}px,
                        ${cropperPosition.left}px ${cropperPosition.bottom}px,
                        ${cropperPosition.right}px ${cropperPosition.bottom}px,
                        ${cropperPosition.right}px ${cropperPosition.top}px,
                        0% ${cropperPosition.top}px
                    )`,
                height: '100%',
                left: 0,
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: 10,
            }}
        /> */}
        {/* Asset */}
        <Image
            alt={asset.title || 'image'}
            fill
            id="canvas-image"
            ref={draggableAssetRef}
            src={asset.signedUrl}
            style={{
                left: imageOffset.x,
                objectFit: 'scale-down',
                top: imageOffset.y,
                transform: `
                    scale(${imageScale})
                    rotate(${imageRotation}deg)`,
            }}
        />
    </Box>
);

AssetEditCanvas.displayName = 'AssetEditCanvas';
