import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { Box, Button, Stack, Typography } from '@deltasierra/react/components/core';
import { FlipAsset } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { AssetEditActions } from './AssetEditActions';
import { AssetEditCanvas } from './AssetEditCanvas';
import { AssetEditFooter } from './AssetEditFooter';
import { AssetEditSlider } from './AssetEditSlider';
import { ROTATION, ZOOM } from './constants';
import { AssetEditState, DraggableAssetProps } from './types';

export type AssetEditProps = AssetEditState &
    DraggableAssetProps & {
        asset: AssetFileFragmentFragment;
        t: Translations<'AssetEdit'>;
    };

const mediumGap = '1.5rem';
const largeGap = '2.5rem';

// eslint-disable-next-line max-lines-per-function
export const AssetEdit = ({
    asset,
    cropperPosition,
    cropRatio,
    draggableAssetRef,
    handleCropRatio,
    handleFlipRotation,
    handleImageRotation,
    handleImageScale,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleResetImageRotation,
    handleResetImageScale,
    imageOffset,
    imageRotation,
    imageScale,
    isDraggingAsset,
    t,
}: AssetEditProps): JSX.Element => (
    <Stack
        sx={{
            columnGap: mediumGap,
            height: '100%',
            paddingBottom: mediumGap,
            paddingLeft: largeGap,
            paddingRight: mediumGap,
        }}
    >
        <Box
            sx={{
                columnGap: '3rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '7.5rem',
                width: '100%',
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <AssetEditCanvas
                    asset={asset}
                    cropRatio={cropRatio}
                    cropperPosition={cropperPosition}
                    draggableAssetRef={draggableAssetRef}
                    handleCropRatio={handleCropRatio}
                    handleImageRotation={handleImageRotation}
                    handleImageScale={handleImageScale}
                    handleMouseDown={handleMouseDown}
                    handleMouseMove={handleMouseMove}
                    handleMouseUp={handleMouseUp}
                    imageOffset={imageOffset}
                    imageRotation={imageRotation}
                    imageScale={imageScale}
                    isDraggingAsset={isDraggingAsset}
                    t={t}
                />
                <Box
                    sx={{
                        columnGap: '9rem',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingTop: mediumGap,
                    }}
                >
                    <AssetEditSlider
                        aria-label={t('Zoom')}
                        defaultValue={ZOOM.defaultValue}
                        header={<Typography variant="h6">{t('Zoom')}</Typography>}
                        max={ZOOM.max}
                        min={ZOOM.min}
                        step={ZOOM.step}
                        value={imageScale}
                        onChange={handleImageScale}
                        onClickReset={handleResetImageScale}
                    />
                    <AssetEditSlider
                        aria-label={t('Rotation')}
                        defaultValue={ROTATION.defaultValue}
                        header={
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '2rem',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Typography variant="h6">{t('Rotation')}</Typography>
                                <Box sx={{ columnGap: '0.5rem', display: 'flex', fontSize: '1px' }}>
                                    <Button
                                        // TODO: Check special characters like ° work internationalisation
                                        aria-label={t('Rotation Negative 90°')}
                                        size="small"
                                        onClick={() =>
                                            handleFlipRotation({
                                                max: ROTATION.max,
                                                min: ROTATION.min,
                                                value: ROTATION.negativeTurn,
                                            })
                                        }
                                    >
                                        <FlipAsset />
                                    </Button>
                                    <Button
                                        aria-label={t('Rotation Positive 90°')}
                                        size="small"
                                        onClick={() =>
                                            handleFlipRotation({
                                                max: ROTATION.max,
                                                min: ROTATION.min,
                                                value: ROTATION.positiveTurn,
                                            })
                                        }
                                    >
                                        <FlipAsset style={{ transform: 'scaleX(-1)' }} />
                                    </Button>
                                </Box>
                            </Box>
                        }
                        max={ROTATION.max}
                        min={ROTATION.min}
                        step={ROTATION.step}
                        value={imageRotation}
                        onChange={handleImageRotation}
                        onClickReset={handleResetImageRotation}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    minWidth: '160px',
                }}
            >
                <AssetEditActions t={t} />
            </Box>
        </Box>
        <AssetEditFooter t={t} onClickCancel={() => null} onClickCrop={() => null} />
    </Stack>
);

AssetEdit.displayName = 'AssetEdit';
