import { Box, Button, Slider, SliderProps, Typography } from '@deltasierra/react/components/core';
import { Minus, Plus } from '@untitled-ui/icons-react';
import { ReactNode } from 'react';

type AssetEditSliderProps = Required<
    Pick<SliderProps, 'aria-label' | 'defaultValue' | 'max' | 'min' | 'onChange' | 'step' | 'value'>
> & {
    header: ReactNode;
    onClickReset: (value: number) => void;
};

export const AssetEditSlider = ({
    defaultValue,
    header,
    onChange,
    onClickReset,
    step,
    ...sliderProps
}: AssetEditSliderProps): JSX.Element => (
    <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ height: '2rem', paddingBottom: '0.2rem' }}>{header}</Box>
        <Slider defaultValue={defaultValue} step={step} onChange={onChange} {...sliderProps} />
        <Box sx={{ alignItems: 'baseline', display: 'flex', justifyContent: 'space-between' }}>
            <Minus />
            <Button onClick={() => onClickReset(typeof defaultValue === 'number' ? defaultValue : 0)}>
                <Typography>{'0'}</Typography>
            </Button>
            <Plus />
        </Box>
    </Box>
);

AssetEditSlider.displayName = 'AssetEditSlider';
