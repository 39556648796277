import { MouseEvent, RefObject, useCallback, useState } from 'react';
import { DraggableAssetProps, DragInfo, Offset } from '../types';

type UseDragAssetProps = {
    draggableAssetRef: RefObject<HTMLDivElement>;
};

export function useDragAsset({ draggableAssetRef }: UseDragAssetProps): DraggableAssetProps {
    const [dragInfo, setDragInfo] = useState<DragInfo>({
        left: 0,
        startX: 0,
        startY: 0,
        top: 0,
    });
    const [finalPosition, setFinalPosition] = useState<Offset>({ x: 0, y: 0 });
    const [isDraggingAsset, setIsDraggingAsset] = useState(false);

    const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingAsset(false);
    };

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        // Getting the mouses initial position on the screen
        const { clientX, clientY } = event;

        const { current: draggableElement } = draggableAssetRef;

        if (!draggableElement) {
            return;
        }

        // Setting initial start position of the Asset of where it currently is placed
        setIsDraggingAsset(true);
        setDragInfo({
            left: draggableElement.offsetLeft, // Offset left within the parent container
            startX: clientX, // Starting X-cords of the mouse
            startY: clientY, // Starting Y-cords of the mouse
            top: draggableElement.offsetTop, // Offset top within the parent container
        });
    };

    const handleMouseMove = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            const { current: draggableElement } = draggableAssetRef;

            if (!isDraggingAsset || !draggableElement) {
                return;
            }
            event.preventDefault();

            // This is the current mouse position
            const { clientX, clientY } = event;

            // Calculate the difference between the current and starting mouse positions
            const delta = {
                x: clientX - dragInfo.startX,
                y: clientY - dragInfo.startY,
            };

            // Calculate the new position of the asset
            const { left, top } = dragInfo;
            setFinalPosition({
                x: left + delta.x,
                y: top + delta.y,
            });
        },

        [isDraggingAsset, dragInfo, draggableAssetRef],
    );
    return {
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        imageOffset: finalPosition,
        isDraggingAsset,
    };
}
